import * as React from 'react';
import { ImageDataLike } from 'gatsby-plugin-image';
import { LayoutProvider } from '../components/layout';
import {
  ProductDetails,
  ProductLanding,
  ProductsParallaxBanner,
  ProductPunchline,
  ProductQuickHighlight,
  ProductReviews,
  Testimonial,
  YouMightAlsoLike,
  ProductSpecs,
} from '../components/sections';
import { StackOnBreadcrumbs } from '../atoms';
import { useViewPortSize } from '../hooks';
import { stackStyled } from '../theme';
import '@splidejs/react-splide/css';

interface ProductDetail {
  pageContext: {
    productsItem: any;
    lifestyleMedia: any;
    details: any;
    quickHighlight: any;
    reviews: any;
  };
  location: { search: string | undefined };
}

export interface IFeatures {
  adjustableShelves: number;
  builtInLights: boolean;
  doorStorageOrganizer: boolean;
  drillResistantHardPlate: boolean;
  fireProof: boolean;
  fireRating: string;
  gunRack: boolean;
  loadBearing: boolean;
  image: ImageDataLike;
  specialFeatures: string;
  warrantyType: string;
  waterProof: boolean;
  waterRating: string;
}

interface Measures {
  depth: number;
  height: number;
  width: number;
}

export interface IDimensions {
  id: string;
  // doorThickness: number;
  // wallThicknes: number;
  specification: Array<{
    exteriorMeasures: Measures;
    interiorMeasures: Measures;
  }>;
  sizeFeat: string;
  weightFeat: number;
  preDrilledFeat: boolean;
}

export interface IFireArmSpecs {
  id: number;
  capacity: Array<{ type: string; value: string }>;
}

export interface IDetailsSpecs {
  numberBolts: number;
  powerType: string;
  mountingLocation: string;
  model: string;
  material: string;
  lockType: string;
  color: string;
  boltDiameter: number;
  bateryType: string;
  image: ImageDataLike;
}

export interface ITestimonial {
  subTitle: string;
  title: string;
  videoUrl: string;
  media: ImageDataLike;
}

export interface IReviews {
  description: string;
  rating: number;
  title: string;
}

const ContentContainer = stackStyled('div', {
  position: 'relative',
  marginTop: '40px',
  width: '100%',
});

const BreadCrumbsContainer = stackStyled('div', (props: { isMobile: boolean }) => ({
  position: 'absolute',
  top: props.isMobile ? '10px' : '30px',
  left: props.isMobile ? '16px' : '40px',
  width: 'fit-content',
}));

const Product = (props: any & ProductDetail) => {
  const testimonial = props.pageContext.testimonial;
  const reviews = props.pageContext.reviews;
  const lifestyle = props.pageContext.lifestyleMedia;
  const productsItem = props.pageContext.productsItem;
  const details = props.pageContext.details;
  const quickHighlight = props.pageContext.quickHighlight;

  const [isMobile, isTablet] = useViewPortSize();
  let addTitle = props.location.search
    ? props.location.search.split('=')[1].split('+').join(' ')
    : '';


  //Slug for category
  const categorySlug = productsItem.stack_on_category.category.toLowerCase().split(' ').join('-');

  //Review const
  const isReview = reviews.length == 0 ? false : true

  return (
    <LayoutProvider
      seo
      locationSearch={props.location.search}
      titleExt={productsItem.productName + ' | ' + productsItem.stack_on_category.category}
      productCategory={productsItem.stack_on_category.category}
    >
      {isMobile || !isTablet && (
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              {
                title: productsItem.stack_on_category.category,
                href: `/categories/${categorySlug}`,
              },
              {
                title: productsItem.productName,
                href: `/product/${productsItem.slug}`,
              },
            ]}
          />
        </BreadCrumbsContainer>
      )}
      <ContentContainer>
        <ProductLanding
          detailImages={productsItem.detailImages || []}
          displayImage={productsItem.productImage}
          safeCategory={productsItem.stack_on_category.category}
          displayName={productsItem.productName}
          seriesTitle={'Hello'}
          seriesDescription={productsItem.smallDescription}
          averagePrice={productsItem.cost}
          reviews={reviews}
          upc={productsItem.upc}
          retailerCategory={productsItem.retailerCategory}
          modelSKU={productsItem.modelSKU}
          ShopOnline={productsItem.ShopOnline}
        />
         <ProductQuickHighlight
          featureOne={quickHighlight.featureOne}
          featureTwo={quickHighlight.featureTwo}
          featureThree={quickHighlight.featureThree}
          featureFour={quickHighlight.featureFour}
        />
        <ProductSpecs
          productName={productsItem.productName}
          features={productsItem.features}
          dimensions={productsItem.dimensions}
          details={productsItem.detailsSpecs}
          capacityGuns={productsItem.fireArmCapacity[0]}
          legalInformation={productsItem.legalInformation}
        />
        <ProductsParallaxBanner background={productsItem.parallaxBanner.url} />
        {details ? <ProductDetails details={details} /> : null}
        <Testimonial
          subTitle={testimonial.subTitle}
          title={testimonial.title}
          videoUrl={testimonial.videoUrl}
          media={testimonial.media}
        />
        <YouMightAlsoLike category={productsItem.stack_on_category.category} />
        {lifestyle.enabled ? <ProductPunchline
          title={lifestyle.title}
          subtitle={lifestyle.subTitle}
          image={lifestyle.media}
        /> : null}
        {isReview && (<ProductReviews reviews={reviews} /> )}
      </ContentContainer>
    </LayoutProvider>
  );
};

export default Product;
